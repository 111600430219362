import { useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

const addZero = function (number) {
  return number < 10 ? '0' + number : number.toString()
}

export default function useTimer (timeout = 0, interval = 1000) {
  const [remaining, setRemaining] = useState(timeout)
  const [isActive, setIsActive] = useState(false)
  const { t } = useTranslation('main')

  function startTimer (expiry) {
    if (expiry) {
      setRemaining(new Date(expiry) - new Date())
    }
    setIsActive(true)
  }

  function stopTimer () {
    setRemaining(timeout)
    setIsActive(false)
  }

  useEffect(() => {
    let timer = null
    if (isActive) {
      timer = setInterval(() => {
        setRemaining(remaining => remaining - interval)
      }, interval)
    } else if (!isActive && remaining > 0) {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [isActive, remaining])

  const seconds = Math.max(Math.floor(remaining % 60000 / 1000), 0)
  const minutes = Math.max(Math.floor(remaining / 60000), 0)
  const hours = Math.max(Math.round(remaining / 3600000), 0)
  const remainingString = hours > 0 ? '~' + hours + t('hour') : addZero(minutes) + ':' + addZero(seconds)

  return { remaining, remainingString, startTimer, stopTimer, setRemaining }
}
