import style from './PullToRefresh.module.css'
import classNames from 'classnames/bind'
import { useEffect, useRef, useState } from 'react'

let timeout

export default function PullToRefresh ({ onRefresh = () => {}, isRefreshing }) {
  const c = classNames.bind(style)
  const refreshElem = useRef()
  const [refreshing, setRefreshing] = useState()

  useEffect(() => {
    if (!isRefreshing) {
      clearTimeout(timeout)
      setRefreshing(false)
    }
  }, [isRefreshing])

  useEffect(() => {
    const maxPullY = 150
    const pullToScroll = e => {
      const top = document.documentElement.scrollTop || document.body.scrollTop
      if (top <= 0) {
        const startX = e.pageX || e.touches[0].pageX
        const startY = e.pageY || e.touches[0].pageY
        let pullDeltaY = 0
        const pulling = e => {
          if (!refreshElem.current) {
            return
          }
          const x = e.pageX || e.touches[0].pageX
          const y = e.pageY || e.touches[0].pageY
          pullDeltaY = (y - startY) / 1.2
          if (Math.abs(x - startX) > 90 || pullDeltaY <= 0) {
            pullDeltaY = 0
            refreshElem.current.style.opacity = '0'
          } else {
            const pc = Math.min(pullDeltaY, maxPullY) / maxPullY
            refreshElem.current.style.opacity = '1'
            refreshElem.current.style.transform = `scaleX(${pc})`
          }
        }
        const stopPulling = e => {
          window.removeEventListener('touchmove', pulling)
          window.removeEventListener('touchend', stopPulling)
          if (pullDeltaY >= maxPullY) {
            onRefresh()
            setRefreshing(true)
            timeout = setTimeout(() => setRefreshing(false), 2500)
          }
          if (refreshElem.current) {
            refreshElem.current.style.opacity = '0'
          }
        }
        window.addEventListener('touchmove', pulling)
        window.addEventListener('touchend', stopPulling)
      }
    }
    window.addEventListener('touchstart', pullToScroll)
    return () => window.removeEventListener('touchstart', pullToScroll)
  }, [])

  return <div className={c('refresh', { refreshing })} ref={refreshElem} />
}
