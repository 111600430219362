import React from 'react'
import PropTypes from 'prop-types'
import withTranslation from 'next-translate/withTranslation'
class TimeAgo extends React.PureComponent {
  static getString (date, options, i18n) {
    const { t, lang: locale } = i18n
    const now = new Date()
    const timeago = date || now

    const ms = now.getTime() - timeago.getTime()

    if (ms < 2 * 60 * 1000) {
      return t('default:justnow')
    } else if (ms < 60 * 60 * 1000) {
      const minutes = Math.floor(ms / 60000)
      return minutes > 1 ? t('default:minutesago_plural', { count: minutes }) : t('default:minutesago', { count: minutes })
    } else if (ms < 24 * 60 * 60 * 1000) {
      const hours = Math.floor(ms / 3600000)
      return hours > 1 ? t('default:hoursago_plural', { count: hours }) : t('default:hoursago', { count: hours })
    } else if (ms < 14 * 24 * 60 * 60 * 1000) {
      const today = new Date(new Date(now).setHours(0, 0, 0, 0))
      const dayago = new Date(new Date(timeago).setHours(0, 0, 0, 0))
      const days = Math.ceil((today.getTime() - dayago.getTime()) / (24 * 60 * 60 * 1000))
      return days > 1 ? t('default:daysago_plural', { count: days }) : t('default:daysago', { count: days })
    } else {
      return timeago.toLocaleDateString(locale, Object.assign({ day: 'numeric', month: 'short', year: ms < 365 * 24 * 60 * 60 * 1000 ? undefined : 'numeric' }, options))
    }
  }

  render () {
    const { lang: locale } = this.props.i18n
    const str = TimeAgo.getString(this.props.date, this.props.options, this.props.i18n)
    return <span {...this.props} title={(this.props.title ? this.props.title + ' ' : '') + (this.props.date || new Date()).toLocaleString(locale, { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: '2-digit' })}>{str}</span>
  }
}

TimeAgo.propTypes = {
  date: PropTypes.instanceOf(Date),
  title: PropTypes.string,
  options: PropTypes.object
}

export default withTranslation(TimeAgo)
