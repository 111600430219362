import useSWR from 'swr'
import useAuth from './useAuth.js'

export function useTransactions (currency = '', limit = '', from = '', to = '') {
  const roundedTo = to ? new Date(to).toISOString() : ''
  const roundedFrom = from ? new Date(from).toISOString() : ''
  const { isAuthed, authToken } = useAuth()
  const { data, error, mutate: updateTransactions } = useSWR(isAuthed && currency ? [`/api/transactions?currency=${currency}&from=${roundedFrom}&to=${roundedTo}&limit=${limit}`, authToken] : null, {
    refreshInterval: 60000
  })

  if (data) {
    data.transactions.sort((a, b) => a.time < b.time ? 1 : -1)
  }

  return {
    transactions: data?.transactions,
    isLoading: !data && !error,
    isError: error,
    updateTransactions
  }
}
