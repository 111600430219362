import style from './Transaction.module.css'

import classNames from 'classnames/bind'
import resolveProductUnit from '../helpers/resolveProductUnit'
import useTranslation from 'next-translate/useTranslation'
import { useEffect } from 'react'
import Link from 'next/link'
import TimeAgo from './TimeAgo'
import useTimer from '../hooks/useTimer'
import { useRouter } from 'next/router'

const purchaseTimeout = null // 48 * 60 * 60000

export default function Transaction ({ id, type, product, productAmount, productUnit, cashAmount, currency, status, time }) {
  const { t } = useTranslation('main')
  const router = useRouter()
  const { locale } = router
  const c = classNames.bind(style)
  const { remaining, remainingString, startTimer } = useTimer()

  useEffect(() => {
    if (purchaseTimeout && status === 'Active' && type === 'purchase') {
      startTimer(new Date(time).getTime() + purchaseTimeout)
    }
  }, [])

  const { productUnitShortLabel, productUnitFractionDigits } = resolveProductUnit(productUnit, productAmount)

  let title
  switch (product) {
    case 'AuGrams':
      title = t('transaction-sku-augrams') + ' '
      break
    case 'AuGramsBonus':
      title = t('transaction-sku-augramsbonus') + ' '
      break
    default:
      title = t('transaction-cash') + ' '
  }

  switch (type) {
    case 'balance':
      title += t('transactions-type-balance')
      break
    case 'sale':
      title += t('transactions-type-sale')
      break
    case 'storage':
      title += t('transactions-type-storage')
      break
    case 'purchase':
      title += t('transactions-type-purchase')
      break
    case 'grant':
      title += t('transactions-type-earned')
      break
    case 'convert':
      title += t('transactions-type-unlocked')
      break
    case 'debit':
      title += t('transactions-type-debit')
      break
    case 'credit':
      title += t('transactions-type-credit')
      break
    case 'deposit':
      title += t('transactions-type-deposit')
      break
    case 'withdrawal':
      title += t('transactions-type-withdrawal')
  }

  const open = ['Open', 'CustomerSent'].includes(status)
  if (id && !open && productAmount !== 0) {
    title += ` #${id}`
  }

  if (purchaseTimeout && status === 'Active' && remaining < 0) {
    status = 'Cancelled'
  }

  let statusLabel
  switch (status) {
    case 'Open':
      statusLabel = type === 'deposit' ? t('transactions-status-paymentrequired') : t('transactions-status-inprogress')
      break
    case 'Completed':
      statusLabel = t('transactions-status-completed')
      break
    case 'CustomerSent':
      statusLabel = t('transactions-status-awaitingfunds')
      break
    case 'Active':
      statusLabel = t('transactions-status-onhold') + (purchaseTimeout ? ' ' + remainingString : '')
      break
    case 'Cancelled':
      statusLabel = t('transactions-status-cancelled')
      break
    case 'Reserved':
      statusLabel = t('transactions-status-reserved')
      break
  }

  const lastMinutes = new Date(time).getTime() > new Date().getTime() - (60000 * 60)

  const tx = (
    <>
      <h4>{title}</h4>
      <div>
        {((status && status !== 'Completed') || lastMinutes) && <span className={c('status', status)}>{statusLabel}</span>}
        {time && <TimeAgo className={c('datetime')} date={time} options={{ month: 'long' }} />}
      </div>
      {productUnit !== currency
        ? <span className={c('productamount', productAmount >= 0 ? 'positive' : 'negative')}>{(productAmount || 0).toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: productUnitFractionDigits })}{productUnitShortLabel}</span>
        : <span className={c('productamount', productAmount >= 0 ? 'positive' : 'negative')}>{(productAmount || 0).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
      {productUnit !== currency && ['purchase', 'sale'].includes(type) && <span className={c('fiatamount')}>{(cashAmount || 0).toLocaleString(locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>}
    </>
  )

  return open && type === 'deposit' ? (
    <Link href={`/payment/${id}`}>
      <a className={c('transaction')}>{tx}</a>
    </Link>
  ) : (
    <span className={c('transaction')}>{tx}</span>
  )
}
